<template>
  <div class="switch-buyer">
      <div class="container">

        <div class="content">
          <img src="../../assets/images/logo3.png"  alt="" @click="$router.push('/')">
          <!-- <p class="title">91再生网买家管理系统</p>
          <p class="span">Store Management System</p> -->
          <p class="switch-text" :style="{textAlign:!list.length && 'center'}">{{list.length ? '切换买家：' : '暂无买家'}}</p>
          
          <div class="buyer" ref="buyer" :style="{overflowY: containerHeight === '22px' && 'auto'}">
            <div ref="item" class="buyer-item" :style="{marginRight: ((index + 1)%2) && containerHeight}" :class="checkBuyer === index && 'buyer-item-check'" v-for="(item,index) in list" :key="index" @click="checkBuyer = index">
                <span>{{item.commercialName}}（{{item.authRoleName}}）</span>
            </div>
          </div>
         

          <div class="buyer-login">
            <a-button type="parmary" v-if="list.length" :disabled="!list.length" @click="login">进入</a-button>
            <a-button v-else @click="$router.push('/buyer')">申请入驻</a-button>
          </div>
        </div>
      </div>

      <FootMain class="foots" />
  </div>
</template>

<script>
import FootMain from '@/layout/FootMain'
import {mapState,mapGetters} from 'vuex'
import cookie from '@/utils/js-cookie.js'
export default {  
  components: {
    FootMain
  },
  data() {
    return {
      checkBuyer: 0,
      checkEntity: cookie.get('checkEntity') || {},
      url: '',
      containerHeight: 0
    }
  },
  computed: {
    ...mapState(['userMenu']),
    ...mapGetters(['getRoleP']),
    list() {
      const list = this.userMenu.find(item => item.entityCharacter === 'P')
      return list ? list.roleVoList : []
    },
    checkSellers() {
      if (this.checkEntity.entityCharacter === 'P' && this.checkEntity.entityId) {
        const data = this.getRoleP.findIndex(item => item.entityId == this.checkEntity.entityId)
        return data === -1 ? 0 : data
      }
      return 0
    }
  },
  mounted() {
    console.log('????', this.checkEntity);
    this.checkEntity = cookie.get('checkEntity') || {}
    this.checkBuyer = this.checkSellers
    this.containerHeight = this.$refs.buyer.scrollHeight > 240 ? '22px' : '40px'
    this.$nextTick(() => {
      console.log(this.$refs.buyer.scrollTop, this.$refs.item[this.checkBuyer].offsetTop);
      if (this.list.length) {
        this.$refs.buyer.scrollTop = this.$refs.item[this.checkBuyer].offsetTop - 35
      }
    })
  },
  beforeRouteEnter(to, from, next) {
      next(vm => {
          vm.url = from.fullPath
      })
  },
  methods: {
    username() {
      return cookie.get('token') && cookie.get('username')
    },
    login() {
      if(!this.list.length) return
      const defaultVal = this.list[this.checkBuyer]
      cookie.set('checkEntity', {entityCharacter: 'P', entityId: defaultVal.entityId})
      // 获取购物车数量
      this.$store.dispatch('cartAmount')
    //   if (this.url.indexOf('product-details') !== -1) {
    //     this.$router.replace(this.url)
    //     return
    //   }
      if (defaultVal.authRoleId === 1) {
        this.$router.push('/buyercenter?entityId='+ defaultVal.entityId)
      } else {
        this.$router.push('/buyercenter/order-management?entityId='+ defaultVal.entityId)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.switch-buyer {
  background: #1abc9c;
  width: 100%;
  height: 100vh;
  position: relative;
  min-height: 800px;
  min-width: 990px;
  @media screen and (max-width: 1440px){
    padding: 0 15px;
  }
  .container {
    margin: 0 auto;
    position: absolute;
    top: 200px;
    right: 50%;
    transform: translateX(50%);
    text-align: center;
    img {
      cursor: pointer;
      width: 260px;
      margin-bottom: 24px;
    }
    .content {
      width: 640px;
      padding: 32px 50px;
      background: #fff;
      border-radius: 10px;
      text-align: center;
      box-shadow: 0px 0px 10px rgb(0 0 0 / 35%);
      .title {
        font-size: 28px;
        text-align: center;
        line-height: 36px;
        color: #1abc9c;
        font-weight: 700;
      }
      .span {
        font-size: 20px;
        text-align: center;
        line-height: 36px;
        color: #1abc9c;
      }
      .switch-text {
        font-size: 18px;
        text-align: left;
        // margin-top: 24px;
      }
      .buyer {
        text-align: left;
        max-height: 240px;
        position: relative;
      }
      .buyer-item {
        display: inline-block;
        position: relative;
        width: 250px;
        text-align: center;
        border: 1px solid #e4e4e4;
        color: #999;
        height: 35px;
        line-height: 14px;
        font-size: 14px;
        border-radius: 3px;
        cursor: pointer;
        margin-top: 24px;
        overflow: hidden;
        span {
            position: absolute;
            width: 100%;
            top: 50%;
            left: 0;
            transform: translate(0, -50%);
            text-align: center;
        }
        &:hover {
          border: 1px solid @primary;
          color: @primary;
        }
        // &:nth-child(2n) {
        //   margin-right: 0;
        // }
      }
      .buyer-item-check {
          border: 1px solid #8400ff;
          color: #8400ff;
      }
      .buyer-login {
        margin-top: 42px;
        text-align: center;
        button {
          width: 300px;
          border-radius: 3px;
          color: #fff;
          height: 50px;
          background: #1abc9c;
        }
      }
    }
  }
  .foots {
    position: absolute;
    width: 100%;
    right: 50%;
    transform: translateX(50%);
    bottom: 64px;
    color: #F6AC1D !important;
    /deep/ a {
      color: #F6AC1D !important;
    }
  }
}
</style>